.scar-textField {
  .MuiInput-formControl {
    &:before {
      border-color: $gray100;
    }
  }

  label {
    color: $gray400;
    font-size: 0.85em;
  }
}

.Microsoft-icon {
  padding-left: 15px;
  padding-bottom: 5px;
}
