.hidden,
.invisible {
  display: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.full-width {
  width: 100%;
}

.cell.legend-hidden {
  // background-color: transparent !important;
  // border-color: transparent !important;
  width: 1px !important;
  height: 1px !important;

  .value-viewer {
    display: none !important;
  }
}
