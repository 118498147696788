.MuiTypography-h6 {
  font-size: 22px !important;
}

// Center fund name with the measurement dates select list in the firm summary
.MuiAccordionSummary-content {
  align-items: center;
}

.underlying-heading {
  color: $black300;
  font-family: $secondaryFont;
  font-size: 0.75em;
  font-style: normal;
  font-weight: bold;
  line-height: 1em;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 2.1875em;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 30%;
    border: 1px solid $green500;
    bottom: -10px;
    left: 35%;
  }
}

.date-text,
.amount-text {
  font-family: $primaryFont;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 1.0625rem;
}
