.scrollbar-container {
  padding-top: 12px;

  &.ps--active-x {
    padding-bottom: 1.25em;
    .ps__rail-x {
      background: $gray300;
      opacity: 1;
      height: 10px;
      transition: height ease 0.3s;
      border-radius: 12px;

      &:hover, &:focus, &.ps--clicking {
        height: 15px;
      }
    }
  }
}

@media only screen and (max-width: 128rem) {
  .hs-grid-container {
    overflow-x: scroll;

    .hs-grid {
      width: 100%;
    }

    // first cell of first row
    .hs-grid tbody tr:first-child td:first-child {
      line-height: 4;
      min-height: 64px;
      position: absolute;
      width: 350px;
      z-index: 1;
    }

    // fist cell from second to last column
    .hs-grid tbody tr:not(:first-child) td:first-child {
      position: absolute;
      width: 350px;
      min-height: 35px;
      margin-top: -1px;
    }

    // all cells
    .hs-grid tbody tr > td,
      // second column
      .hs-grid tbody tr > td:nth-child(2) {
      line-height: 2;
      width: 150px;
    }
    // second columns
    .hs-grid tbody tr > td:nth-child(2) {
      padding-left: 380px !important;
    }
  }
}

// Styles for wider(2048+) screen sizes
@media only screen and (min-width: 129rem) {
  .hs-grid {
    width: 100%;
  }

  .hs-grid tbody tr:first-child td:first-child,
  .hs-grid tbody tr:not(:first-child) td:first-child {
    position: relative !important;
  }

  .hs-grid-container .hs-grid tbody tr > td:nth-child(2) {
    padding-left: 10px !important;
  }
}
