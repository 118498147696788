.data-grid {
  table-layout: auto;
  border-collapse: collapse;

  .col-legend.ltm-or-ntm-legend {
    background-color: $blue;
    color: whitesmoke;
    border-bottom: 1px solid $primary200;
    border-left: 1px solid $blue;
    border-right: 1px solid $blue;
  }
  // Column Legends
  .column-legend {
    &__icons {
      display: none;
    }
    &__icon {
      padding: 0;
      margin: 0 0.25rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
    &:hover {
      .column-legend {
        &__legend.has-icons {
          display: none;
        }
        &__icons {
          display: block;
        }
      }
    }
  }
}

.data-grid-container,
.data-grid-container:focus {
  outline: none;
}

.data-sheet {
  width: 100%;
}

.security-table-sc-spreadsheet {
  .data-grid-container table {
    background-color: transparent;
  }
}
