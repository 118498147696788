// dialogs
.MuiDialog-container {
  .MuiDialogTitle-root {
    padding-top: 24px;

    h2 {
      font-size: 18px !important;
    }
  }

  #opm-inputs {
    tr td {
      .text-cell {
        text-align: right;
        padding-right: 20px;
        margin-right: 0px;
      }
      &:first-child {
        .value-viewer, .text-cell {
          text-align: left;
          padding-right: 0px;
          margin-left: 0px;
        }
      }
    }
  }

  #opm-input-table {
    .data-grid td {
      &.table-header {
        span#row-title {
          text-align: right;
          padding-right: 20px;
        }
      }
      &:first-child {
        &.table-header {
          span#row-title {
            text-align: left;
            padding-right: 0px;
            margin-left: 0px;
          }
        }
        span.value-viewer  {
          text-align: left;
          padding-right: 0px;
          margin-left: 0px;
        }
      }
    }
  }

  .equity-value-table {
    .data-grid td {
      .MuiInputBase-input {
        text-align: end;
      }
      .MuiInputAdornment-positionEnd {
        margin-left: 4px;
      }
    }
  }

  .data-grid td {
    .MuiInputBase-input {
      text-align: left;
    }
    &:first-child span.value-viewer {
      text-align: right;
      span.text-cell {
        padding-right: 0;
      }
    }
    &.align-left {
      &:first-child span.value-viewer {
        text-align: left !important;
      }
    }
  }

  // Start of styles for SpreadSheet used in Ledger
  #spreadsheet-table__title-columns {
    .data-grid td {
      &:first-child span.value-viewer, .legend {
        text-align: center;
        span.text-cell {
          padding-right: 20px;
        }
      }
    }
  }

  div[id*="__content-columns"]{
    .data-grid td {
      .MuiInputBase-input {
        text-align: right;
      }
      span.value-viewer {
        text-align: right;
        span.text-cell {
          padding-right: 20px;
        }
      }
      &.legend {
        span.value-viewer {
          text-align: center;
          span.text-cell {
            padding-right: 0px;
          }
        }
      }
    }
  }

  #spreadsheet-table__total-column {
    .data-grid td {
      span.value-viewer {
        text-align: right;
        span.text-cell {
          padding-right: 20px;
        }
      }
      .table-header {
        text-align: center;
      }
    }
  }
  #cash-distribution-ledger, #shares-ledger {
    .data-grid tr {
      &:not(:first-child) td {
        &.read-only {
          div>div>div>div{
            text-align: left !important;
            justify-content: flex-start !important;
            margin-left: 6px !important;
          }
        }
      }
    }
  }
  // End of styles for SpreadSheet used in Ledger
}
