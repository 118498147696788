.MuiTablePagination-root {
    width: auto;
    .MuiToolbar-gutters {
      padding-left: 1rem
    }
    .MuiTablePagination-toolbar {
      color: grey;
      font-size: 1rem;
      padding-right: 1rem;
    }
    .MuiTablePagination-caption {
      order: 1;
    }
    .MuiTablePagination-selectRoot {
      margin-left: 0;
      order: 2;
    }
    .MuiTablePagination-spacer {
      order: 3;
    }
    .MuiTablePagination-caption:nth-of-type(2n) {
      order: 4;
    }
    .MuiTablePagination-actions {
      order: 5;
    }
}
