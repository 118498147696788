html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
menu,
time,
mark,
audio,
video,
canvas {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $secondaryFont;
}

body {
  background-color: #e3e9ed;
  height: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $blue300;
}

#root {
  height: 100%;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.ps__thumb-x {
  background-color: #79797b;
}

.ps.always-visible .ps__rail-x {
  opacity: 0.6;
}

.ps.always-visible .ps__rail-y {
  opacity: 0.6;
}

// Reset input autofill styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-appearance: menulist-button;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  background-color: $white !important;
  background-image: none !important;
  color: currentColor !important;
}

.MuiSelect-select:focus {
  background-color: $white !important;
}
