// TODO: replace color hex with variables
.scalar-logo {
  height: 1.875rem;

  .scalar-logo--text {
    fill: $black300;
  }
  .scalar-logo--circle {
    fill: $black300;
  }
  .scalar-logo--icon {
    fill: $white;
  }
}

.scalar-version-icon {
  vertical-align: -2px;
  padding-left: 0.25rem;
}
