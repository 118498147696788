.inline-message-box {
  display: inline-block;
  margin: #{$spacing * 2} 0;
  width: auto;

  &__container {
    align-items: center;
    background-color: $disabled !important;
    border: 2px solid $gray400;
    display: flex;
    justify-content: center;
    padding: calc($spacing / 2) $spacing;
  }

  &__text {
    color: $gray400;
    flex: 1;
    font-weight: bold;
    line-height: 1.75;
  }

  &__icon {
    color: $gray400;
    font-size: 1.75rem !important;
    margin-right: $spacing;
    padding: 0.5rem;
    padding-left: 0;
  }

  // Variations
  &--full-with {
    width: 100%;
  }
}
