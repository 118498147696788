.message-box {
  text-align: center;
  margin: auto;

  &__container {
    text-align: center;
    padding: #{$spacing * 4} #{$spacing * 8};
    margin: auto;
    max-width: 500px;
  }

  &__title {
    color: $primaryColor;
    margin-bottom: #{$spacing * 2};
  }

  &__tagline {
    margin-bottom: #{$spacing * 2};
    line-height: 1.75
  }

  &__icon {
    font-size: 3rem !important;
    padding: 1.5rem;
    margin-bottom: #{$spacing * 2};
    background-color: $primary100;
    color: $white;
    border-radius: 50%;
  }

  &__inside-icon-ok {
    font-size: 2.5rem !important;
    padding: 2rem;
    margin-bottom: #{$spacing * 2};
    background-color: $green500;
    color: $white;
    border-radius: 50%;
  }

  &__inside-icon-error {
    font-size: 1.5rem !important;
    padding: 1.5rem;
    margin-bottom: #{$spacing * 2};
    background-color: $error;
    color: $white;
    border-radius: 50%;
  }

  &__icon-error-disabled {
    font-size: 3rem !important;
    padding: 1.5rem;
    margin-bottom: #{$spacing * 2};
    background-color: $gray400;
    color: $white;
    border-radius: 50%;
  }

  // Variations
  &--with-frame {
    .message-box__container {
      padding: #{$spacing * 8};
    }
  }

  &--with-bg-color {
    .message-box__container {
      background-color: $gray100;
      padding: #{$spacing * 4} #{$spacing * 2};
      margin: #{$spacing * 2} $spacing;
    }
  }

  &--full-with {
    .message-box__container {
      padding: #{$spacing * 8};
      max-width: 100%;
    }
  }

  &--info {
    .message-box__icon {
      color: $white;
      background-color: $primaryColor;
    }
  }

  &--success {
    .message-box__container {
      background-color: $secondary100;
      border: 2px solid $green500;
    }

    .message-box__icon {
      color: $white;
      background-color: $green500;
    }
  }

  &--error {
    .message-box__icon {
      color: $white;
      background-color: $error;
    }

    .message-box__container {
      background-color: $error100;
      border: 2px solid $error;
    }

    .message-box__title {
      color: $error;
    }
  }

  &--error-disabled {
    .message-box__icon {
      color: $white;
      background-color: $error;
    }

    .message-box__title {
      color: $gray400;
    }
  }
}