//fonts
$primaryFont: 'Kadwa', serif, times;
$secondaryFont: 'Open Sans', sans-serif, arial;

// colors
$primary900: #043a6e;
$primary800: #075185;
$primary700: #0c71a6;
$primary600: #004a8b;
$primary500: #037de8;
$primary400: #0386de;
$primary300: #0a88d7;
$primary200: #76c4f5;
$primary100: #afe0ff;
$primary50: #ddf6fc;
$primaryColor: $primary500;

$blue: $primary400;
$blue100: $primary100;
$blue300: $primary300;
$blue500: $primary500;
$blue600: $primary600;
$blue700: $primary700;
$blue800: $primary800;
$gray900: #0e1427;
$gray800: #171e2f;
$gray700: #242c3b;
$gray600: #353c46;
$gray500: #494d52;
$gray400: #8a9097;
$gray300: #bdc4cb;
$gray200: #e3e9ed;
$gray100: #f0f4f6;
$black300: #293033;
$white: #fff;

$secondary900: #135f4b;
$secondary800: #207255;
$secondary700: #328e65;
$secondary600: #49aa75;
$secondary500: #65c687;
$secondary400: #8bdca0;
$secondary300: #a7edb2;
$secondary200: #c8f9cb;
$secondary100: #e4fce3;
$green: #00b200;
$green500: $secondary500;
$cellError: #ffe9e9;
$readOnly: #f9fcfc;
$disabled: #f0f4f6;
$textColor: black;
$cellIconColor: $primary600;

$error: #ff4070;
$error100: #fff5f8;
$success: #44b140;
$warning: #dea638;
$warning500: $warning;
$warning100: #fff4de;

// sizes
$spacing: 8px;
$fontSize: 12px;
$defaultCellWidth: 175px;
$defaultLabelCellWidth: 275px;
$maxCellWidth: $defaultCellWidth;
$cellFontSize: 14px;
$labelCellFontSize: 13px;

:root {
  --blue-300: #{$blue300};
  --blue-500: #{$blue500};
}

:export {
  primary900: $primary900;
  primary800: $primary800;
  primary700: $primary700;
  primary600: $primary600;
  primary500: $primary500;
  primary400: $primary400;
  primary300: $primary300;
  primary200: $primary200;
  primary100: $primary100;
  primary50: $primary50;
  primaryColor: $primaryColor;
  blue: $blue;
  blue100: $blue100;
  blue300: $blue300;
  gray900: $gray900;
  gray800: $gray800;
  gray700: $gray700;
  gray600: $gray600;
  gray500: $gray500;
  gray400: $gray400;
  gray300: $gray300;
  gray200: $gray200;
  gray100: $gray100;
  black300: $black300;
  white: $white;
  secondary900: $secondary900;
  secondary800: $secondary800;
  secondary700: $secondary700;
  secondary600: $secondary600;
  secondary500: $secondary500;
  secondary400: $secondary400;
  secondary300: $secondary300;
  secondary200: $secondary200;
  secondary100: $secondary100;
  green: $green;
  green500: $green500;
  cellError: $cellError;
  readOnly: $readOnly;
  disabled: $disabled;
  textColor: $textColor;
  cellIconColor: $cellIconColor;
  defaultCellWidth: $defaultCellWidth;
  defaultLabelCellWidth: $defaultLabelCellWidth;
}
