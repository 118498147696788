#custom-auto-select-popover {
    .MuiPopover-paper {
        max-height: 35% !important;
    }
}

#folder-backdrop {
    z-index: 1;
    background-color: rgb(0 0 0 / 10%);
}

#folders-content {
    width: 100%;
    >div {
        padding: 0;
    }
}

div#documents {
    .folder {
        &.MuiPaper-elevation1 {
            border-bottom: none;
        }
    }
    .droppable {
        border: none;
        background-color: white;
        transform: scale(1);
        transition: 0.15s all ease;
    }
    .droppable.drag-over:not(div.dragging) {
        border: dashed 2px $primary400;
        transform: scale(1);
        transition: 0.15s all ease;
        background-color: $gray100;
    }
}

.MuiTreeItem-root {
    padding: 0px 5px !important;
    .MuiTreeItem-content {
        .MuiTreeItem-iconContainer {
            color: $primary500;
        }
        .MuiTreeItem-label {
            padding: 0.5rem;
            &:hover {
                background-color: $gray100;
            }
        }
    }
}

.custom-tree-container {
    .MuiCollapse-root {
        border-left-color: $primary500;
    }
    .MuiFormHelperText-contained {
        margin-top: 0.5rem;
        margin-left: 0 !important;
        opacity: 0.5;
    }
}

.add-new-folder-button {
    .MuiListItem-gutters {
        padding-left: 0.125rem;
    }
    .MuiListItemIcon-root {
        min-width: 1.875rem;
    }
}
